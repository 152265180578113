<template>
  <div class="stat-section">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="stat-content mx-auto">
        <!-- COLUMN  -->
        <div
          class="column"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="80"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="top-text">43,500+</div>
          <div class="bottom-text">Past & curated questions</div>
        </div>

        <!-- COLUMN  -->
        <div
          class="column"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="100"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="top-text">510,000+</div>
          <div class="bottom-text">Practice tests taken so far</div>
        </div>

        <!-- COLUMN  -->
        <div
          class="column"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="80"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div class="image-top">
            <div class="icon icon-android"></div>
            <div class="icon icon-windows-angle"></div>
            <div class="icon icon-apple"></div>
          </div>
          <div class="bottom-text">Available on multiple platforms</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "statSection",
};
</script>

<style lang="scss" scoped>
.stat-section {
  margin: toRem(30) 0;
  background: #e8f4f8;
  padding: toRem(81) 0;

  @include breakpoint-down(sm) {
    padding: toRem(60) 0;
  }

  .stat-content {
    @include flex-row-between-wrap;

    .column {
      width: 33%;
      @include flex-column-center;

      @include breakpoint-down(sm) {
        margin-bottom: toRem(40);
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .top-text {
        font-weight: 700;
        color: $brand-dark-blue;
        @include font-height(34, 42);

        @include breakpoint-down(md) {
          @include font-height(30, 38);
        }
      }

      .bottom-text {
        @include font-height(13, 21);
        color: $bluish-grey;

        @include breakpoint-down(md) {
          @include font-height(12.5, 19);
        }
      }

      .image-top {
        @include flex-row-between-nowrap;
        margin-bottom: toRem(3);

        .icon {
          margin: 0 toRem(13);
          font-size: toRem(28);
          color: $brand-dark-blue;
        }
      }
    }
  }
}
</style>
