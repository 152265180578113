<template>
  <div class="about-page">
    <!-- ABOUT HERO BANNER  -->
    <about-hero-banner />

    <!-- FAQ SECTION  -->
    <faq-section :faqs="faqs" />

    <!-- STAT SECTION  -->
    <stat-section />

    <!-- CONTACT SECTION  -->
    <contact-section />
  </div>
</template>

<script>
import aboutHeroBanner from "@/modules/landing/components/about-comps/about-hero-banner";
import faqSection from "@/modules/landing/components/about-comps/faq-section";
import statSection from "@/modules/landing/components/about-comps/stat-section";
import contactSection from "@/modules/landing/components/about-comps/contact-section";

export default {
  name: "aboutPage",

  metaInfo: {
    title: "About",
  },

  components: {
    aboutHeroBanner,
    faqSection,
    statSection,
    contactSection,
  },

  data() {
    return {
      faqs: [
        {
          title: "How can Class54 help me to prepare for my examination?",
          value:
            "Yes, Class54 can help you as long as we have content for your planned examination. All you have to do is to download and install either our mobile app or the Windows desktop software to get started. We have a rich plethora of well-explained past and curated questions by experts and they will surely help you prepare for and pass your examinations.",
        },
        {
          title: "How much does it cost to access Class54?",
          value:
            "It costs a token of NGN1,500 for One-month access, NGN2,500 for 3-months access, and NGN4,000 for 1-year access. Our goal is to make examination preparation accessible for every person on earth and so we have made Class54 very affordable for you.",
        },
        {
          title: "How many exams can I access after subscribing to Class54?",
          value:
            "Once you subscribe to use Class54 on mobile or on the web, you will have unlimited access to all the exams on the platform and to any other features that we may introduce to the platform.",
        },
        {
          title: "How do I subscribe to Class54?",
          value:
            "You can subscribe automatically by using your debit card through the app or on the web. Or you can simply do a bank transfer to any of our bank accounts after which you can call/text us so that we can activate your subscription quickly. ",
        },
        {
          title: "On which devices can I use Class54?",
          value:
            "You can install the Class54 mobile app on any android device (version 10 and above) and on any IOS device. The JAMB/UTME desktop software, that can only be installed on a Windows PC.",
        },
        {
          title: "What happens to my subscription if I change my device?",
          value:
            "Your account is tied to your registered phone number, therefore, you can easily move your account/subscription to any other device as you need to. In the case of the JAMB/UTME desktop software, you will have to send us your new serial number in order for us to re-activate your subscription on the new device.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.about-page {
}
</style>
