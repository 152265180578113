<template>
  <div
    class="faq-card white-text-bg rounded-25"
    data-aos="fade-up"
    data-aos-offset="200"
    data-aos-delay="130"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
  >
    <!-- TOP ROW  -->
    <div class="top-row pointer mgb-0" @click="toggleAccordion">
      <div class="text brand-dark-blue font-weight-700">
        {{ title }}
      </div>

      <div class="avatar">
        <div
          class="icon smooth-animation"
          :class="is_open ? 'icon-minus' : 'icon-plus'"
        ></div>
      </div>
    </div>

    <!-- BOTTOM ROW  -->
    <div
      class="bottom-row blackish-grey smooth-transition mgt-12"
      v-if="is_open"
    >
      <div class="smooth-animation">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqCard",

  props: {
    title: {
      type: String,
      default:
        "Can I see how it works before committing to a subscription package?",
    },

    value: {
      type: String,
      default:
        "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    },
  },

  data: () => ({
    is_open: false,
  }),

  methods: {
    toggleAccordion() {
      this.is_open = !this.is_open;
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-card {
  border: toRem(1) solid #e0e0e0;
  padding: toRem(25) toRem(32);
  margin-bottom: toRem(16);
  // box-shadow: 0 toRem(18) toRem(80) rgba($brand-black, 0.04),
  //   0 toRem(11.6667) toRem(46.8519) rgba($brand-black, 0.0303704),
  //   0 toRem(6.93333) toRem(25.4815) rgba($brand-black, 0.0242963),
  //   0 toRem(3.6) toRem(13) rgba($brand-black, 0.02),
  //   0 toRem(1.46667) toRem(6.51852) rgba($brand-black, 0.0157037),
  //   0 toRem(0.333333) toRem(3.14815) rgba($brand-black, 0.00962963);

  @include breakpoint-down(xs) {
    padding: toRem(18) toRem(20);
  }

  .top-row {
    @include flex-row-between-nowrap;
    align-items: flex-start;
    margin-bottom: toRem(14);

    .text {
      @include font-height(16, 22);
      padding-right: toRem(15);

      @include breakpoint-down(sm) {
        @include font-height(14, 18);
      }

      @include breakpoint-down(xs) {
        @include font-height(13.5, 18);
      }
    }

    .avatar {
      border: toRem(2) solid $brand-dark-blue;
      @include square-shape(22);

      .icon {
        @include center-placement;
        color: $brand-dark-blue;
        font-size: toRem(11);
        font-weight: 700;
      }
    }
  }

  .bottom-row {
    @include font-height(16, 28);

    @include breakpoint-down(sm) {
      @include font-height(15, 22);
    }

    @include breakpoint-down(xs) {
      @include font-height(13.75, 21);
    }
  }
}
</style>
