<template>
  <div
    class="testimonial-card rounded-25 border-brand-border-grey w-100 h-auto"
  >
    <div class="content">
      <div class="description blackish-grey">
        {{ testimonial.comment }}
      </div>
    </div>

    <div class="card-author">
      <a :href="testimonial.link" target="_blank" rel="noopener noreferrer">
        <img
          v-lazy="staticAsset('Youtube.svg', 'landing')"
          alt="Youtube"
          class="icon"
        />
      </a>

      <div class="author-detail">
        <div class="name font-weight-700 blackish-grey">
          {{ testimonial.name }}
        </div>

        <div class="info grey-accent">
          Scored {{ testimonial.score }} in {{ testimonial.exam }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "testimonialCard",

  props: {
    testimonial: Object,
  },
};
</script>

<style lang="scss">
.testimonial-card {
  padding: toRem(40) toRem(30);
  margin-bottom: toRem(10);

  @include breakpoint-down(xs) {
    padding: toRem(30) toRem(20);
    margin-bottom: toRem(16);
  }

  .content {
    height: toRem(240);

    @include breakpoint-down(md) {
      height: toRem(200);
    }

    .description {
      @include font-height(15.25, 27);

      @include breakpoint-down(sm) {
        @include font-height(15, 28);
      }
    }
  }

  .card-author {
    @include flex-row-start-nowrap;

    a {
      .icon {
        margin-right: toRem(7);
        @include rectangle-shape(55, 36);
        @include transition(0.4s);

        @include breakpoint-down(sm) {
          @include rectangle-shape(52, 33);
        }
      }
      &:hover {
        .icon {
          translate: scale(1.075);
        }
      }
    }

    .name {
      @include font-height(17, 24);

      @include breakpoint-down(sm) {
        @include font-height(16, 23);
      }
    }

    .info {
      @include font-height(14, 19);
    }
  }
}
</style>