<template>
  <div
    class="desktop-download-card"
    data-aos="fade-up"
    data-aos-offset="200"
    data-aos-delay="80"
    data-aos-duration="1000"
    data-aos-easing="ease-in"
  >
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="desktop-content mx-auto">
        <!-- DOWNLOAD CARD -->
        <div class="download-card mx-auto">
          <!-- TITLE TOP -->
          <div class="title-top">
            <img v-lazy="staticAsset('JAMB.png', 'subscriptions')" alt="" />
            <!-- TITLE TEXT -->
            <div class="title-text font-weight-700 brand-dark-blue">
              JAMB CBT Software - Offline (2024)
            </div>
          </div>

          <!-- DESCRIPTION -->
          <div class="description blackish-grey">
            <p class="mgb-25">
              JAMB CBT software by Class54 is a Windows computer testing &
              preparatory software designed to help candidates to prepare for
              the JAMB CBT examination. We have error-free questions based on
              JAMB/UTME past and expert-curated questions from 2000 to date.
            </p>

            <p>
              This offline software does NOT require an internet connection. It
              tracks the candidate, marks and scores with detailed explanations
              and statistics of the area of strength and weaknesses in all
              topics of the mock examination.
            </p>
          </div>

          <!-- DESKTOP IMAGE -->
          <div class="desktop-image">
            <img
              v-lazy="staticAsset('DesktopScreen.png', 'landing')"
              alt="class54Desktop"
              class="w-100 h-auto"
            />
          </div>

          <div
            class="
              divider
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
            "
          >
            <!-- OPTION -->
            <div class="options">
              <!-- DOWNLOAD COUNT -->
              <div class="option">
                <div class="icon icon-download"></div>
                <div class="text">
                  <span class="font-weight-700">{{ download_count }}</span>
                  <span> Downloads</span>
                </div>
              </div>

              <!-- DOWNLOAD SIZE -->
              <div class="option">
                <div class="icon icon-memory-card"></div>
                <div class="text">
                  <span class="font-weight-700">{{ download_size }}</span>
                </div>
              </div>
            </div>

            <!-- BUTTON ROW -->
            <div class="button-row">
              <!-- ACTIVATE SOFTWARE  -->
              <router-link to="/activate-desktop" class="btn btn-outline mgr-15"
                >Activate Software</router-link
              >

              <!-- <a
              href="https://d2fdcybmr3du6d.cloudfront.net/JAMB-2021/utme.exe"
              download
              @click="incrementCount"
              class="btn btn-primary"
              >Download Now
            </a> -->

              <button @click="toggleDesktopDownload" class="btn btn-primary">
                Download Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <portal to="54-modals">
      <transition name="fade" v-if="show_desktop_download">
        <desktop-download-modal
          @increaseCount="download_count++"
          @closeTriggered="toggleDesktopDownload"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "desktopDownloadCard",

  components: {
    desktopDownloadModal: () =>
      import(
        /* webpackChunkName: "modal" */ "@/modules/landing/modals/desktop-download-modal"
      ),
  },

  data() {
    return {
      download_count: 0,
      download_size: "137 MB",

      payload: {
        exam_id: "1",
        year_id: "56",
        increment: "false",
      },

      show_desktop_download: false,
    };
  },

  mounted() {
    this.fetchDownloadData();
  },

  methods: {
    ...mapActions({
      updateDownloadCount: "storeSubscription/updateDownloadCount",
    }),

    toggleDesktopDownload() {
      this.show_desktop_download = !this.show_desktop_download;
    },

    fetchDownloadData() {
      this.updateDownloadCount(this.payload)
        .then((response) => {
          if (response.status_code == 200) {
            this.download_count = response.data.downloads;
            this.download_size = response.data.size;
          }
        })
        .catch((err) => console.log(err));
    },

    incrementCount() {
      setTimeout(() => {
        this.payload.increment = "true";
        this.fetchDownloadData();
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-download-card {
  .download-card {
    // box-shadow: 0px 18px 80px rgba(0, 0, 0, 0.04),
    //   0px 11.6667px 46.8519px rgba(0, 0, 0, 0.0303704),
    //   0px 6.93333px 25.4815px rgba(0, 0, 0, 0.0242963),
    //   0px 3.6px 13px rgba(0, 0, 0, 0.02),
    //   0px 1.46667px 6.51852px rgba(0, 0, 0, 0.0157037),
    //   0px 0.333333px 3.14815px rgba(0, 0, 0, 0.00962963);

    width: 75%;
    padding: toRem(40);
    border-radius: toRem(25);
    border: toRem(1) solid #e0e0e0;
    margin: toRem(150) 0 toRem(96);

    @include breakpoint-down(md) {
      width: 90%;
      padding: toRem(35) toRem(30);
    }

    @include breakpoint-down(sm) {
      width: 100%;
      margin: toRem(120) 0 toRem(65);
      padding: toRem(32) toRem(24);
    }

    @include breakpoint-down(xs) {
      padding: toRem(28) toRem(21);
    }

    .title-top {
      @include flex-row-start-nowrap;
      margin-bottom: toRem(24);

      img {
        margin-right: toRem(15);
        @include square-shape(37);

        @include breakpoint-down(md) {
          @include square-shape(35);
        }
      }

      .title-text {
        @include font-height(23, 28);

        @include breakpoint-down(md) {
          @include font-height(16.75, 24);
        }

        @include breakpoint-down(sm) {
          @include font-height(16, 22);
        }
      }
    }

    .description {
      @include font-height(15.5, 27);
      margin-bottom: toRem(16);

      @include breakpoint-down(md) {
        @include font-height(14.5, 25);
      }

      @include breakpoint-down(sm) {
        @include font-height(14, 25);
      }
    }

    .desktop-image {
      margin: toRem(20) auto toRem(16);
      width: 93%;

      @include breakpoint-down(sm) {
        width: 100%;
      }
    }

    .divider {
      margin-top: toRem(27);
      padding-top: toRem(30);
      border-top: toRem(1) solid #e0e0e0;

      @include breakpoint-down(xs) {
        margin-top: toRem(30);
      }
    }

    .options {
      color: $blackish-grey;
      @include flex-row-start-nowrap;
      @include font-height(14.85, 26);

      @include breakpoint-down(md) {
        margin-bottom: toRem(25);
        @include font-height(14.5, 25);
      }

      @include breakpoint-down(xs) {
        @include font-height(14.25, 24);
      }

      .option {
        @include flex-row-start-nowrap;

        &:first-of-type {
          margin-right: toRem(20);
        }

        .icon {
          margin-right: toRem(9);
          font-size: toRem(16);
        }
      }
    }

    .button-row {
      @include flex-row-end-nowrap;

      @include breakpoint-down(sm) {
        width: 100%;
      }

      @include breakpoint-down(xs) {
        @include flex-column-center;
        justify-content: flex-start;
      }

      .btn-outline {
        padding: toRem(15) toRem(35);

        @include breakpoint-down(xs) {
          margin-right: 0 !important;
          padding: toRem(16) toRem(40);
          margin-bottom: toRem(25);
          font-size: toRem(14.25);
          width: 100%;
        }
      }

      .btn-primary {
        padding: toRem(16.5) toRem(40);

        @include breakpoint-down(xs) {
          padding: toRem(17) toRem(48);
          font-size: toRem(14.25);
          width: 100%;
        }
      }

      .btn {
        @include breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}
</style>
