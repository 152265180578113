<template>
  <div class="home-page">
    <!-- HERO SECTION -->
    <home-hero-banner />

    <!-- EXAM LIST SECTION -->
    <!-- <exam-list-section /> -->
    <new-exam-list-section />

    <!-- CTA BLOCK ONE -->
    <cta-block-one />

    <!-- STAT SECTION  -->
    <stat-section />

    <!-- SUBSCRIPTION SECTION -->
    <!-- <subscription-section /> -->

    <!-- CTA BLOCK TWO -->
    <cta-block-two />

    <!-- TESTIMONIAL SECTION -->
    <testimonial-section />
  </div>
</template>

<script>
import homeHeroBanner from "@/modules/landing/components/home-comps/home-hero-banner";
// import examListSection from "@/modules/landing/components/home-comps/exam-list-section";
import newExamListSection from "@/modules/landing/components/home-comps/new-exam-list-section";
import ctaBlockOne from "@/modules/landing/components/home-comps/cta-block-one";
import ctaBlockTwo from "@/modules/landing/components/home-comps/cta-block-two";
import testimonialSection from "@/modules/landing/components/home-comps/testimonial-section";
import statSection from "@/modules/landing/components/about-comps/stat-section";
// import subscriptionSection from "@/modules/landing/components/home-comps/subscription-section";

export default {
  name: "homePage",

  metaInfo: {
    title:
      "JAMB questions and answers 2024, JAMB 2024, JAMB CBT APP, JAMB Software, [Free Download]",
    titleTemplate: "%s || Class54",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    homeHeroBanner,
    newExamListSection,
    ctaBlockOne,
    ctaBlockTwo,
    statSection,
    testimonialSection,
    // examListSection,
    // subscriptionSection,
  },
};
</script>

<style lang="scss" scoped></style>
